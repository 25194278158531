<template>
  <div>
    <v-text-field
      dense
      outlined
      counter
      @keypress.enter="focus('.code input')"
      :label="$t('data.title')"
      :error="!!apiError.title"
      :error-messages="apiError.title"
      autofocus
      maxlength="100"
      v-model="formData.title"
    ></v-text-field>

    <v-text-field
      dense
      outlined
      counter
      class="code"
      :label="$t('page.data.code')"
      :error="!!apiError.code"
      :error-messages="apiError.code"
      maxlength="100"
      v-model="formData.code"
    ></v-text-field>
  </div>
</template>

<script lang="babel" type="text/babel">
import listCreatePopupMixin from '@/components/list/listCreateButton/listCreatePopupMixin'
import uuidV4 from 'uuid/v4'
export default {
  mixins: [listCreatePopupMixin],
  data: () => ({
    formData: {
      title: null,
      code: uuidV4(),
    },
  }),
}
</script>

<style lang="sass" type="text/sass" scoped></style>